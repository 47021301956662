<template lang="pug">
section.promo(@click="$root.$emit('settings-selector-sale')")
  picture
    source(media="(min-width: 2500px)", :srcset="`${promoBanners.super.webp['x1'] + imagesVersion} 1x, ${promoBanners.super.webp['x2'] + imagesVersion} 2x`", type="image/webp")
    source(media="(min-width: 1920px)", :srcset="`${promoBanners.large.webp['x1'] + imagesVersion} 1x, ${promoBanners.large.webp['x2'] + imagesVersion} 2x`", type="image/webp")
    source(media="(min-width: 1024px)", :srcset="`${promoBanners.standard.webp['x1'] + imagesVersion} 1x, ${promoBanners.standard.webp['x2'] + imagesVersion} 2x`", type="image/webp")
    source(media="(min-width: 768px)", :srcset="`${promoBanners.tablet.webp['x1'] + imagesVersion} 1x, ${promoBanners.tablet.webp['x2'] + imagesVersion} 2x`", type="image/webp")
    source(:srcset="`${promoBanners.mobile.webp['x1'] + imagesVersion} 1x, ${promoBanners.mobile.webp['x1'] + imagesVersion} 2x`", type="image/webp")
    img(:srcset="`${promoBanners.tablet.jpeg['x1'] + imagesVersion} 768w, ${promoBanners.standard.jpeg['x1'] + imagesVersion} 1024w, ${promoBanners.large.jpeg['x1'] + imagesVersion} 1920w, ${promoBanners.super.jpeg['x1'] + imagesVersion} 2500w`", :src="promoBanners.mobile.jpeg['x1'] + imagesVersion", type="image/jpeg", :alt="saleHeadline")
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PromoComponent',

  data: () => ({
    windowWidth: null
  }),

  computed: {
    ...mapState({
      saleHeadline: (state) => state.app.saleHeadline,
      imagesVersion: (state) => state.imagesVersion,
      promoBanners: (state) => state.app.promoBanners
    }),

    imageVersion() {
      return this.imagesVersion ? '?v=' + this.imagesVersion : ''
    },

    promo() {
      return process.env.promo
    }
  }
}
</script>

<style lang="sass" scoped>
.promo
  cursor: pointer
  border-top: 1px solid $border-color
  +mobile
    border-top: unset
  ::v-deep
    img
      width: 100%
      @media screen and (max-width: 767px)
        aspect-ratio: 0.56
      @media (min-width: 768px)
        aspect-ratio: 1.6
      @media (min-width: 1024px)
        aspect-ratio: 2.66
      @media (min-width: 1920px)
        aspect-ratio: 3.66
      @media (min-width: 2500px)
        aspect-ratio: 5.71
</style>
